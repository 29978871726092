//////ページトップ
$(function () {
  var topBtn = $('#page-top');
  topBtn.hide();
  $(window).scroll(function () {
      if ($(this).scrollTop() > 500) {
          topBtn.fadeIn();
      } else {
          topBtn.fadeOut();
      }
  });
  topBtn.click(function () {
      $('body,html').animate({
          scrollTop: 0
      }, 500);
      return false;
  });
})
//////スムーススクロール
$(function(){
$('a[href^="#"]').click(function(){
  var speed = 800;
  var href= $(this).attr("href");
  var target = $(href == "#" || href == "" ? 'html' : href);
  var position = target.offset().top;
  $("html, body").animate({scrollTop:position}, speed, "swing");
  return false;
});
});

//////リンク
$(document).ready(function(){

   $("div.link,.btn_link").click(function(){
       if($(this).find("a").attr("target")=="_blank"){
           window.open($(this).find("a").attr("href"), '_blank');
       }else{
           window.location=$(this).find("a").attr("href");
       }
   return false;
   });

})
//////ナビゲーション
$(function(){
$('.menu_trigger').on('click',function(){
  $(this).toggleClass('active');
  $('nav').slideToggle();
  return false;
});
});
var windowWidth = $(window).width();
var windowSm = 1050;
if (windowWidth <= windowSm) {
} else {
$("ul.drawer_nav li").mouseenter(function(){
$(this).siblings().find("ul").hide();
$(this).children().slideDown(150);
});
$('html').hover(function() {
  $('ul.drawer_nav ul').slideUp(150);
});
$('.nav_line').hover(function(){
  $(".drawer_nav_inner:not(:animated)", this).slideDown();
    }, function(){
  $(".drawer_nav_inner",this).slideUp();
});
$('.menu_trigger').click(function(){ 
  $('header').toggleClass('open_nav');
});
$('nav a').on('click', function(){
  if (window.innerWidth <= 900) {
    $('.menu_trigger').click();
  }
});
}  

//////SP電話番号表示
var ua = navigator.userAgent.toLowerCase();
var isMobile = /iphone/.test(ua)||/android(.+)?mobile/.test(ua);

if (!isMobile) {
  $('a[href^="tel:"]').on('click', function(e) {
      e.preventDefault();
  });
}